import React, { useEffect, useState } from "react";
import clsx from "clsx";

type Props = { isOpen?: boolean };

export const HamburgerIcon = ({ isOpen = false }: Props) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClick = () => setOpen((prev) => !prev);

  return (
    <div
      className={clsx("hamburger", { "hamburger--open": open })}
      onClick={handleClick}
    >
      <span className="hamburger__element hamburger__element--top" />
      <span className="hamburger__element hamburger__element--mid" />
      <span className="hamburger__element hamburger__element--bottom" />
    </div>
  );
};
