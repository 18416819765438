import React from "react";
import { useTranslation } from "react-i18next";

import DownloadIcon from "assets/icons/download.svg";

type Props = { link: string };

export const DownloadBox = ({ link }: Props) => {
  const { t } = useTranslation();

  return (
    <section className="downloadBox">
      <a href={link} download className="downloadBox__link">
        <div className="downloadBox__text">{t`config.download.text`}</div>
        <div className="downloadBox__icon">
          <DownloadIcon />
        </div>
      </a>
    </section>
  );
};
