import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { TMetadata } from "constants/common";
import { IData } from "interfaces";
import JSONData from "../../images/partners/info.json";

const query = graphql`
  {
    allFile(
      filter: {
        relativeDirectory: { eq: "partners" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            id
          }
          name
          ext
        }
      }
    }
  }
`;

export const ImgGrid = () => {
  const [img, setImg] = useState<IData[]>([]);
  const data = useStaticQuery(query);
  const jsData: TMetadata = JSONData;
  const {
    allFile: { edges: images },
  } = data;

  useEffect(() => {
    images.sort((a: IData, b: IData) =>
      a.node.name > b.node.name ? 1 : b.node.name > a.node.name ? -1 : 0
    );
    setImg(images);
  }, [images]);

  return (
    <section className="img-grid">
      {img.map(({ node: { childImageSharp: imgData, name, ext } }: IData) => {
        const image = getImage(imgData);
        const metadata = jsData.find(({ key }) => key === name + ext);
        if (!image) return;
        return (
          <div className="img-grid__content" key={metadata?.key || name + ext}>
            {metadata?.link ? (
              <a
                className="img-grid__link"
                href={metadata.link}
                target="_blank"
              >
                <GatsbyImage
                  className="img-grid__image"
                  alt={metadata?.alt || name}
                  image={image}
                />
              </a>
            ) : (
              <GatsbyImage
                className="img-grid__image"
                alt={metadata?.alt || name}
                image={image}
              />
            )}
          </div>
        );
      })}
    </section>
  );
};
