import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { HamburgerIcon } from "./HamburgerIcon";

type Props = { children: React.ReactNode };

export const Layout = ({ children }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
