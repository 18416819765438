import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

import { Button, Counter } from "components";

const query = graphql`
  query {
    hero: file(
      relativeDirectory: { eq: "home" }
      relativePath: { regex: "/hero.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
      name
      ext
    }
  }
`;

export const Hero = () => {
  const { hero } = useStaticQuery(query);
  const heroImage = getImage(hero);
  const { t } = useTranslation();

  return (
    <header className="hero">
      <div className="hero__container">
        <div className="hero__content">
          <h1 className="hero__title">{t`home.title`}</h1>
          <h2 className="hero__text">{t`home.secondTitle`}</h2>
          <Button
            className="hero__btn"
            text="config.button.more"
            type="outline-w"
            scrollId="#info"
          />
        </div>
        {heroImage && (
          <GatsbyImage
            className="hero__img"
            alt={hero.name}
            image={heroImage}
          />
        )}
      </div>
      <div className="hero__counter">
        <Counter />
      </div>
    </header>
  );
};
