import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "./Button";

type Props = { title?: string; date?: string; text?: string; link?: string };

export const MaterialCard = ({ title, date, text, link }: Props) => {
  const { t } = useTranslation();

  const RenderHeader = () => (
    <header className="materialCard__header">
      <h2 className="materialCard__title">{title}</h2>
      <div className="materialCard__date">{date}</div>
    </header>
  );

  return (
    <article className="materialCard">
      {link ? (
        <Link to={link} className="materialCard__link">
          <RenderHeader />
        </Link>
      ) : (
        <RenderHeader />
      )}

      <section className="materialCard__body">
        <p className="materialCard__description">{text}</p>
        <Button
          text={t`config.button.readMore`}
          type="outline"
          to={link}
          className="materialCard__btn"
        />
      </section>
    </article>
  );
};
