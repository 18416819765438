import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  const { t } = useTranslation();
  const [field, setField] = useState<string[]>([]);
  const [discipline, setDiscipline] = useState<string[]>([]);

  useEffect(() => {
    setDiscipline(
      t("informations.discipline", { defaultValue: [], returnObjects: true })
    );
    setField(
      t("informations.field", { defaultValue: [], returnObjects: true })
    );
  }, []);

  return (
    <div className="about">
      <div className="about__text">
        <p>{t("informations.paragraph_1")}</p>
        <p>{t("informations.paragraph_2")}</p>
        <ul>
          {discipline.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
        <p>{t("informations.paragraph_3")}</p>
        <ul>
          {field.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </div>
      <StaticImage
        className="about__img"
        objectFit="contain"
        placeholder="blurred"
        src="../../images/home/baner.png"
        alt="about us"
      />
    </div>
  );
};
