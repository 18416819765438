import React, { useMemo } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import moment from "moment";

import { path } from "constants/common";

export const Footer = () => {
  const { t } = useTranslation();

  const currentYear = useMemo(() => moment().format("YYYY"), []);

  return (
    <footer className="footer">
      <nav className="footer__list container">
        <Link className="footer__item" to={path.informations}>
          {t("config.nav.informations")}
        </Link>
        <Link className="footer__item" to={path.publications}>
          {t("config.nav.publications")}
        </Link>
        <Link className="footer__item" to={path.fee}>
          {t("config.nav.fee")}
        </Link>
        <Link className="footer__item" to={path.accomodation}>
          {t("config.nav.accomodation")}
        </Link>
        <Link className="footer__item" to={path.contact}>
          {t("config.nav.contact")}
        </Link>
      </nav>
      <p className="footer__copy">&copy; YSA {currentYear}</p>
    </footer>
  );
};
