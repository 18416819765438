import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type Props = {
  title: string;
  secondTitle?: string;
  center?: boolean;
  container?: boolean;
};

export const SectionTitle = ({
  title,
  secondTitle,
  center,
  container,
}: Props) => {
  const { t } = useTranslation();

  return (
    <header
      className={clsx("section-title", {
        "section-title--center": center,
        "section-title--container": container,
      })}
    >
      <h2 className="section-title__title">{title}</h2>
      <span className="section-title__line"></span>
      {secondTitle && <p className="section-title__second">{t(secondTitle)}</p>}
    </header>
  );
};
