import moment from "moment";

export const countdownTime = (date: string) => {
  const eventDate = moment(date);
  const todaysDate = moment();

  const days = eventDate.diff(todaysDate, "days");
  const hours = eventDate.diff(todaysDate, "hours") - days * 24;
  const minutes =
    eventDate.diff(todaysDate, "minutes") - days * 24 * 60 - hours * 60;

  if (days < 0 || hours < 0 || minutes < 0)
    return { days: 0, hours: 0, minutes: 0 };

  return { days, hours, minutes };
};
