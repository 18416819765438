import React, { useState } from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import clsx from "clsx";

import { path } from "constants/common";
import { HamburgerIcon } from "./HamburgerIcon";
import { Logo } from "./Logo";

type TMenuType = { isMobile?: boolean };

export const NavMenu = () => {
  const { t } = useTranslation();
  const { languages, originalPath } = useI18next();
  const [displayMenu, setDisplayMenu] = useState(false);
  const { pathname } = useLocation();

  const handleClick = () => setDisplayMenu((prev) => !prev);

  const MenuList = ({ isMobile }: TMenuType) => (
    <>
      <Link
        className="nav-menu__item"
        activeClassName="nav-menu__item--active"
        to={path.home}
      >
        {t("config.nav.home")}
      </Link>
      <Link
        className="nav-menu__item"
        activeClassName="nav-menu__item--active"
        to={path.informations}
      >
        {t("config.nav.informations")}
      </Link>
      <Link
        className="nav-menu__item"
        activeClassName="nav-menu__item--active"
        to={path.publications}
      >
        {t("config.nav.publications")}
      </Link>
      <Link
        className="nav-menu__item"
        activeClassName="nav-menu__item--active"
        to={path.fee}
      >
        {t("config.nav.fee")}
      </Link>
      <Link
        className="nav-menu__item"
        activeClassName="nav-menu__item--active"
        to={path.accomodation}
      >
        {t("config.nav.accomodation")}
      </Link>
      <Link
        className={clsx(
          "nav-menu__item",
          pathname.includes(path.materials) && "nav-menu__item--active"
        )}
        activeClassName="nav-menu__item--active"
        to={path.materials}
      >
        {t("config.nav.materials")}
      </Link>
      {isMobile && (
        <Link
          className="nav-menu__item"
          activeClassName="nav-menu__item--active"
          to={path.contact}
        >
          {t("config.nav.contact")}
        </Link>
      )}
      <div className="nav-menu__lang">
        {isMobile &&
          languages.map((lng) => (
            <Link
              className="header__item"
              activeClassName="header__item--active"
              to={originalPath}
              language={lng}
              key={lng}
            >
              {lng}
            </Link>
          ))}
      </div>
    </>
  );

  return (
    <nav className="nav-menu">
      <div className="nav-menu__container">
        <Logo mobile />
        <div className="nav-menu__icon" onClick={handleClick}>
          <HamburgerIcon isOpen={displayMenu} />
        </div>
        <div className="nav-menu__list">
          <MenuList />
        </div>
      </div>
      <div
        className={clsx("nav-menu__mobile", {
          "nav-menu__mobile--show": displayMenu,
          "nav-menu__mobile--hide": !displayMenu,
        })}
      >
        <MenuList isMobile />
      </div>
    </nav>
  );
};
