import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { countdownTime } from "utils";

type TCountdown = { days: number; hours: number; minutes: number } | null;

export const Counter = () => {
  const { t } = useTranslation();
  const date = t("home.date", { returnObjects: true });
  const [countdownData, setCountdownData] = useState<TCountdown>(
    countdownTime(date)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdownData(countdownTime(date));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="counter">
      {countdownData && (
        <>
          <div className="counter__item counter__item--days">
            <h2 className="counter__header">{countdownData?.days}</h2>
            <p className="counter__text">
              {t("config.counter.days", { count: countdownData?.days })}
            </p>
          </div>
          <div className="counter__item counter__item--time">
            <h2 className="counter__header">{countdownData?.hours}</h2>
            <p className="counter__text">
              {t("config.counter.hours", { count: countdownData?.hours })}
            </p>
          </div>
          <div className="counter__item counter__item--time">
            <h2 className="counter__header">{countdownData?.minutes}</h2>
            <p className="counter__text">
              {t("config.counter.minutes", { count: countdownData?.minutes })}
            </p>
          </div>
        </>
      )}
    </section>
  );
};
