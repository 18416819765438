import React from "react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import scrollTo from "gatsby-plugin-smoothscroll";
import clsx from "clsx";

type Props = {
  text: string;
  className?: string;
  type?: "outline" | "outline-w";
  scrollId?: string;
  to?: string;
  newTab?: boolean;
};

type E = React.MouseEvent<HTMLButtonElement>;

export const Button = ({
  text,
  type,
  className,
  scrollId,
  to,
  newTab,
}: Props) => {
  const { t } = useTranslation();
  const buttonStyle = clsx("button", className, `button--${type}`);

  const handleClick = (e: E) => {
    e.preventDefault();
    scrollId && scrollTo(scrollId);
    newTab ? window.open(to, "_blank") : to && navigate(to);
  };

  const mouseHandler = (e: E) =>
    e.button === 1 && to && window.open(to, "_blank");

  return (
    <button
      className={buttonStyle}
      onClick={handleClick}
      onMouseDown={mouseHandler}
    >
      {t(text)}
    </button>
  );
};
