import React from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

import { NavMenu, Logo } from "components";
import { path } from "constants/common";

export const Header = () => {
  const { languages, originalPath } = useI18next();
  const { t } = useTranslation();

  return (
    <header className="header">
      <div className="header__top">
        <Logo />
        <div className="header__list">
          {languages.map((lng) => (
            <Link
              className="header__item"
              activeClassName="header__item--active"
              to={originalPath}
              language={lng}
              key={lng}
            >
              {lng}
            </Link>
          ))}
          <Link
            className="header__item header__item--normal"
            activeClassName="header__item--active"
            to={path.contact}
          >
            {t`config.nav.contact`}
          </Link>
        </div>
      </div>
      <NavMenu />
    </header>
  );
};
