import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import { useTranslation } from "react-i18next";

import CheckIcon from "assets/icons/check-circle.svg";

export const ContactForm = () => {
  const [state, handleSubmit] = useForm("mzbonrpp");
  const { t } = useTranslation();

  if (state.succeeded)
    return (
      <div className="contact-form__message">
        <CheckIcon />
        <p className="contact-form__confirm">{t`config.contact.confirm`}</p>
      </div>
    );

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <input
        className="contact-form__input"
        id="name"
        type="text"
        name="name"
        placeholder={t`config.contact.name`}
      />
      <input
        className="contact-form__input"
        id="email"
        type="email"
        name="email"
        placeholder={t`config.contact.email`}
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <textarea
        className="contact-form__text-area"
        id="message"
        name="message"
        placeholder={t`config.contact.text`}
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        className="contact-form__button"
        type="submit"
        disabled={state.submitting}
      >
        {t`config.contact.send`}
      </button>
    </form>
  );
};
