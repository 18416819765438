import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import clsx from "clsx";

import { path } from "constants/common";

type Props = { mobile?: boolean };

export const Logo = ({ mobile }: Props) => {
  const { t } = useTranslation();

  return (
    <h1 className={clsx("logo", { logo__mobile: mobile })}>
      <Link to={path.home}>{t`config.header.name`}</Link>
    </h1>
  );
};
