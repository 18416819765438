export const path = {
  home: "/",
  informations: "/informations/",
  publications: "/publications/",
  fee: "/fee/",
  accomodation: "/accomodation/",
  contact: "/contact/",
  materials: "/materials/",
};

export type TMetadata = {
  key: string;
  alt?: string;
  link?: string;
}[];
